@font-face {
  font-family: 'AdihausDIN';
  src: local('AdihausDIN'), url(./fonts/AdihausDIN.ttf) format('truetype');
}

@font-face {
  font-family: 'AdihausDINbold';
  src: local('AdihausDINbold'), url(./fonts/AdihausDIN_Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'AdihausDINcn';
  src: local('AdihausDINcn'), url(./fonts/AdihausDIN_Cn.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: AdihausDIN,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2A2F3E;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}