.App {
  text-align: center;
}

#intro {
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 200px;
  text-align: left;
}

#khaled-bitmoji {
  width: 120px;
}

#bitmoji-name-container {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: -120px;
}

#name-rect-bg {
  background-color: white;
  width: 450px;
  height: 100px;
  text-align: center;
}

#name {
  line-height: 100px;
}

#services-container {
  display: flex;
  flex-flow: row wrap;
  gap: 100px;
  justify-content: space-between;
}

#data-science {
  margin-right: 75px;
}

.adi-cn {
  font-family: AdihausDINcn;
}

.adi-bold {
  font-family: AdihausDINbold;
}

.blue {
  color: #2A2F3E;
}

.justify {
  text-align: justify;
}

.left {
  text-align: left;
}

.caps {
  text-transform: uppercase;
}

.max-width-765 {
  max-width: 765px;
}

.horizontal-center {
  margin-left: auto;
  margin-right: auto;
}

.size-14 {
  font-size: 14px;
}

.size-20 {
  font-size: 20px;
}

.size-40 {
  font-size: 40px;
}

.size-32 {
  font-size: 32px;
}

.margin-0 {
  margin: 0 0 0 0;
}

.section-title {
  margin-top: 150px;
  margin-bottom: 50px;
}

.project-description {
  margin-bottom: 25px;
  margin-top: -10px;
}

#contact-button {
  width: 190px;
  height: 40px;
  background: white;
  cursor: pointer;
  padding: 0;
  border: none;
  font-family: AdihausDINbold;
  text-transform: uppercase;
}

#contact-container {
  display: flex;
  max-width: 320px;
  justify-content: space-between;
  margin-bottom: 150px;
}

#imprint-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

#imprint-popup-content {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  margin: auto;
  background: white;
  color: #2A2F3E;
  text-align: left;
  padding-left: 15px;
  overflow-y: scroll;
}

@media only screen and (max-width: 800px) {
  .max-width-765 {
    max-width: 90vw;
  }

  #data-science {
    margin-right: 0;
  }

  #services-container {
    flex-flow: column;
  }

  #intro {
    max-width: 90vw;
  }

  #technologies-logo {
    transform: scale(95%);
  }
}

@media only screen and (max-width: 500px) {
  #name-rect-bg {
    width: 300px;
    height: 50px;
    text-align: center;
  }

  #name {
    line-height: 50px;
    font-size: 20px;
  }
}